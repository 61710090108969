import React from 'react';

const TimerIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill='none'
    >
      <g clipPath='url(#clip0_109_4017)'>
        <path
          d='M6.69901 1.63811C5.60701 1.63811 4.58968 1.91345 3.64701 2.46411C2.73234 2.99611 1.99968 3.71478 1.44901 4.62011C0.889011 5.54411 0.599678 6.54745 0.581011 7.63011C0.562345 8.75011 0.823678 9.79545 1.36501 10.7661C1.88768 11.7088 2.60634 12.4601 3.52101 13.0201C4.46368 13.5894 5.49035 13.8834 6.60101 13.9021C7.73968 13.9114 8.79434 13.6361 9.76501 13.0761C10.6797 12.5441 11.4123 11.8161 11.963 10.8921C12.5137 9.96811 12.7983 8.96945 12.817 7.89611C12.8357 6.76678 12.5697 5.71678 12.019 4.74611C11.4777 3.80345 10.7403 3.05211 9.80701 2.49211C8.85501 1.92278 7.81901 1.63811 6.69901 1.63811ZM6.74101 12.6701C5.84501 12.6701 5.00968 12.4508 4.23501 12.0121C3.49768 11.5828 2.90968 10.9948 2.47101 10.2481C2.02301 9.49211 1.79901 8.66845 1.79901 7.77711C1.79901 6.88578 2.02301 6.05745 2.47101 5.29211C2.90034 4.55478 3.48368 3.97145 4.22101 3.54211C4.98635 3.09411 5.81235 2.87011 6.69901 2.87011C7.58568 2.87011 8.40701 3.09411 9.16301 3.54211C9.90034 3.97145 10.4883 4.55478 10.927 5.29211C11.375 6.05745 11.599 6.88345 11.599 7.77011C11.599 8.62878 11.3867 9.43145 10.962 10.1781C10.5373 10.9248 9.96101 11.5221 9.23301 11.9701C8.46768 12.4274 7.63701 12.6608 6.74101 12.6701ZM7.11901 4.10211H6.29301C6.26501 4.10211 6.24168 4.11145 6.22301 4.13011C6.20435 4.14878 6.19501 4.17211 6.19501 4.20011V7.14011C6.19501 7.16811 6.18335 7.19145 6.16001 7.21011C6.13668 7.22878 6.11101 7.23811 6.08301 7.23811H4.20701C4.17901 7.23811 4.15568 7.24745 4.13701 7.26611C4.11834 7.28478 4.10901 7.30811 4.10901 7.33611V8.17611C4.10901 8.20411 4.11834 8.22745 4.13701 8.24611C4.15568 8.26478 4.17901 8.27411 4.20701 8.27411H7.11901C7.14701 8.27411 7.17035 8.26478 7.18901 8.24611C7.20768 8.22745 7.21701 8.20411 7.21701 8.17611V4.20011C7.21701 4.17211 7.20768 4.14878 7.18901 4.13011C7.17035 4.11145 7.14701 4.10211 7.11901 4.10211Z'
          fill='#212529'
        />
      </g>
      <defs>
        <clipPath id='clip0_109_4017'>
          <rect
            width='14'
            height='14'
            fill='white'
            transform='matrix(1 0 0 -1 0.580078 14.7701)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TimerIcon;
