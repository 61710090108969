import React from 'react';
import classes from './Infleance.module.css';
function Influeance({ img, title }) {
  return (
    <div className={classes.influeance}>
      <img
        src={img}
        alt='inf-img'
      />
      <h5>{title}</h5>
    </div>
  );
}

export default Influeance;
