import React from 'react';

const StarIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <g clipPath='url(#clip0_109_3879)'>
        <path
          d='M13.9529 6.2802C13.7929 6.4502 13.5429 6.70521 13.2029 7.04521L13.1579 7.09021C12.2679 7.96021 11.5979 8.61021 11.1479 9.04021C11.0079 9.18021 10.9029 9.29021 10.8329 9.37021C10.7929 9.41021 10.7729 9.47021 10.7729 9.55021L11.5379 13.9902V14.0202C11.5279 14.0302 11.4779 14.0102 11.3879 13.9602C11.2079 13.8702 10.9479 13.7352 10.6079 13.5552L7.50289 11.9202C7.43289 11.8802 7.35789 11.8852 7.27789 11.9352L5.74789 12.7452C4.70789 13.2952 3.92289 13.7052 3.39289 13.9752C3.37289 13.9952 3.35289 14.0052 3.33289 14.0052C3.28289 14.0252 3.26789 13.9852 3.28789 13.8852L3.45289 12.8502C3.71289 11.3702 3.90289 10.2602 4.02289 9.52021C4.03289 9.50021 4.03039 9.47271 4.01539 9.43771C4.00039 9.40271 3.98289 9.37521 3.96289 9.35521L3.45289 8.86021C2.78289 8.21021 2.28289 7.71521 1.95289 7.37521C1.64289 7.07521 1.29289 6.7052 0.902891 6.2652C0.872891 6.23521 0.862891 6.20771 0.872891 6.1827C0.882891 6.1577 0.907891 6.1402 0.947891 6.13021L4.54789 5.6052C4.82789 5.55521 5.03789 5.5252 5.17789 5.51521C5.23789 5.50521 5.28289 5.4902 5.31289 5.47021C5.34289 5.4502 5.37289 5.4102 5.40289 5.35021C6.24289 3.63021 6.87789 2.34021 7.30789 1.4802L7.35289 1.4052C7.36289 1.38521 7.37789 1.37271 7.39789 1.36771C7.41789 1.36271 7.43539 1.3702 7.45039 1.39021C7.46539 1.4102 7.47289 1.43021 7.47289 1.4502L9.40789 5.3652C9.44789 5.4552 9.51789 5.50521 9.61789 5.51521L13.9079 6.14521C13.9479 6.14521 13.9729 6.1577 13.9829 6.1827C13.9929 6.20771 13.9829 6.2402 13.9529 6.2802Z'
          fill='#FF912C'
        />
      </g>
      <defs>
        <clipPath id='clip0_109_3879'>
          <rect
            width='15.02'
            height='15'
            fill='white'
            transform='matrix(1 0 0 -1 0.859375 15.1902)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StarIcon;
