import React from 'react';
import classes from './Course.module.css';
import CourseImg from '../../assests/couresImg.jpg';
import Love from '../../assests/Love';
import BtnRead from '../BtnRead/BtnRead';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import ArrowRight from '../../assests/ArrowRight';
function Course({ price = 'Free' }) {
  return (
      <motion.div className={classes.course} data-aos="flip-right">
          <div className={classes.course__container}>
              <div className={classes.container__tag}>
                  <span>
                      <Love />
                  </span>
                  <button>Evidence-based teaching strategies</button>
              </div>
              <img src={CourseImg} alt="courseImg" />
          </div>
          <h4>Dimensional analysis and effect size</h4>
          <hr />
          <div className={classes.course__tag}>
              <span>{price}</span>
              <Link to="/course-details" className={classes.btnRead}>
                  ReadMore &nbsp; <ArrowRight />
              </Link>
          </div>
      </motion.div>
  );
}

export default Course;
