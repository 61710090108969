import React from 'react';
import classes from './MiddleExpert.module.css';
import TextAssessment from '../TextAssessment/TextAssessment';
import EffectLogo from '../../assests/expertTeacher.png';
import SpeedMoter from '../SpeedMoter/SpeedMoter';
import SmartText from '../SmartText/SmartText';
function MiddleExpert() {
  return (
    <section className={classes.middle__expert}>
      <div>
        <SpeedMoter />
      </div>
      <div className={classes.empact__teaching}>
        <h2>know the Impact of Teaching Strategies</h2>
        <SmartText>
          There are hundreds of factors, teaching methods and strategies that
          affect student achievement. The major key for effective teaching is to
          know the impact of factors and strategies that contribute to student
          achievement.
        </SmartText>
        <SmartText>
          John Hattie, one of the foremost experts on effective teaching, has
          amassed a database of research on the classroom factors and teaching
          methods that improve student learning, and quantifying the effect they
          have. Hattie compiled 800 meta-analyses of more than 52,000 studies of
          240 million students to rank the effectiveness of different teaching
          practices. Hattie distils his research into a simple proposition for
          teachers – “know the impact”.
        </SmartText>
        <SmartText>
          Hattie studied six areas that contribute to learning: the student, the
          home, the school, the curricula, the teacher, and teaching and
          learning approaches. Hattie developed a way of ranking various
          influences in different meta-analyses related to learning and
          achievement according to their effect sizes. He ranked 138 influences
          that are related to learning outcomes. Hattie found that the average
          effect size of all the interventions he studied was 0.40. Therefore he
          decided to judge the success of influences relative to this ‘hinge
          point’, in order to find an answer to the question “What works best in
          education?”
        </SmartText>
      </div>
      <div className={classes.effect__size}>
        <h2>What is Effect Size?</h2>
        <TextAssessment>
          An effect size is a quantitative measure of the difference between two
          groups. Effect size calculation is based on the ‘standardised mean
          difference’ (SMD) between two groups in a trial, this is the
          difference between the average score of participants in the
          intervention group, and the average score of participants in the
          control group.
        </TextAssessment>
        <div className={classes.effect__size__img}>
          <img
            src={EffectLogo}
            alt='effect_logo'
          />
        </div>
      </div>
    </section>
  );
}

export default MiddleExpert;
