import React from 'react';

const BtnArrowLeft = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='31'
      height='32'
      viewBox='0 0 31 32'
      fill='none'
    >
      <path
        d='M23.4556 18.0334C23.6756 18.0334 23.8606 17.9584 24.0106 17.8084C24.1606 17.6584 24.2356 17.4734 24.2356 17.2534V16.5934C24.2356 16.3734 24.1606 16.1884 24.0106 16.0384C23.8606 15.8884 23.6756 15.8134 23.4556 15.8134H11.0956V12.5134C11.0956 12.2934 11.0406 12.1634 10.9306 12.1234C10.8206 12.0834 10.6956 12.1334 10.5556 12.2734L6.47562 16.3834C6.33563 16.5234 6.26562 16.6984 6.26562 16.9084C6.26562 17.1184 6.33563 17.3034 6.47562 17.4634L10.5556 21.5434C10.6956 21.7034 10.8206 21.7634 10.9306 21.7234C11.0406 21.6834 11.0956 21.5534 11.0956 21.3334V18.0334H23.4556Z'
        fill='#212529'
      />
    </svg>
  );
};

export default BtnArrowLeft;
