import React from 'react';

const YoutubeIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
    >
      <g clipPath='url(#clip0_109_4332)'>
        <path
          d='M16.9902 6.86397H16.9582V6.39997C16.9582 6.30397 16.9476 6.15997 16.9262 5.96797V5.93597C16.9262 5.85063 16.9156 5.72263 16.8942 5.55197L16.8622 5.16797C16.8516 4.9973 16.8409 4.87463 16.8302 4.79997C16.7876 4.60797 16.7129 4.33063 16.6062 3.96797C16.5316 3.7013 16.4142 3.4773 16.2542 3.29597C15.9022 2.87997 15.4329 2.63997 14.8462 2.57597C14.6542 2.55463 14.3716 2.5333 13.9982 2.51197L13.1182 2.46397C12.5849 2.44263 12.1742 2.43197 11.8862 2.43197C11.3529 2.41063 10.9476 2.39997 10.6702 2.39997H9.88624C9.17157 2.3893 8.63823 2.3893 8.28623 2.39997C7.7529 2.39997 6.9529 2.41063 5.88623 2.43197L4.54223 2.47997C4.0729 2.5013 3.71823 2.52263 3.47823 2.54397C3.23823 2.5653 3.00623 2.5973 2.78223 2.63997C2.25957 2.76797 1.8649 3.05063 1.59823 3.48797C1.4169 3.77597 1.2889 4.12797 1.21423 4.54397C1.17157 4.74663 1.1289 5.05063 1.08623 5.45597C1.0649 5.64797 1.04357 5.9413 1.02223 6.33597V6.38397C1.0009 6.7253 0.990234 6.9813 0.990234 7.15197C0.990234 8.11197 0.995568 8.83197 1.00623 9.31197C1.00623 9.43997 1.0169 9.6213 1.03823 9.85597C1.05957 10.1973 1.0809 10.4533 1.10223 10.624L1.11823 10.816C1.1289 10.9866 1.13957 11.1146 1.15023 11.2C1.1929 11.4773 1.27823 11.792 1.40623 12.144C1.6089 12.6986 2.00357 13.0773 2.59023 13.28C2.83557 13.3653 3.20357 13.4293 3.69423 13.472H3.71023C3.8489 13.4826 4.05157 13.4933 4.31823 13.504L4.62223 13.52C4.96357 13.5413 5.21957 13.552 5.39023 13.552L6.57423 13.584L9.59824 13.616L12.0142 13.568L13.0062 13.536C13.1556 13.536 13.2676 13.5306 13.3422 13.52H13.3902C13.8489 13.4986 14.1956 13.4773 14.4302 13.456C14.7289 13.4346 14.9529 13.408 15.1022 13.376C15.8382 13.2053 16.3289 12.7893 16.5742 12.128C16.7449 11.68 16.8462 11.2 16.8782 10.688C16.8996 10.5173 16.9209 10.256 16.9422 9.90397L16.9902 8.76797V6.86397ZM7.34223 10.08V5.58397L11.6622 7.83997L7.34223 10.08Z'
          fill='#191B1E'
        />
      </g>
      <defs>
        <clipPath id='clip0_109_4332'>
          <rect
            width='16.02'
            height='16'
            fill='white'
            transform='matrix(1 0 0 -1 0.980469 16)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default YoutubeIcon;
