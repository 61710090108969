import React from 'react';

const BtnArrowRight = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='31'
      height='32'
      viewBox='0 0 31 32'
      fill='none'
    >
      <path
        d='M7.51703 15.8121C7.29703 15.8121 7.11203 15.8871 6.96203 16.0371C6.81203 16.1871 6.73703 16.3721 6.73703 16.5921V17.2521C6.73703 17.4721 6.81203 17.6571 6.96203 17.8071C7.11203 17.9571 7.29703 18.0321 7.51703 18.0321H19.877V21.3321C19.877 21.5521 19.932 21.6821 20.042 21.7221C20.152 21.7621 20.277 21.7121 20.417 21.5721L24.497 17.4621C24.637 17.3221 24.707 17.1471 24.707 16.9371C24.707 16.7271 24.637 16.5421 24.497 16.3821L20.417 12.3021C20.277 12.1421 20.152 12.0821 20.042 12.1221C19.932 12.1621 19.877 12.2921 19.877 12.5121V15.8121L7.51703 15.8121Z'
        fill='#212529'
      />
    </svg>
  );
};

export default BtnArrowRight;
