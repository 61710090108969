import React from 'react';

const LockIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
    >
      <path
        d='M5 21.5V9.5H8V7.5C8 6.386 8.388 5.44067 9.164 4.664C9.94 3.88733 10.8853 3.49933 12 3.5C13.114 3.5 14.0593 3.888 14.836 4.664C15.6127 5.44 16.0007 6.38533 16 7.5V9.5H19V21.5H5ZM12 17C12.422 17 12.7773 16.8553 13.066 16.566C13.3553 16.2773 13.5 15.922 13.5 15.5C13.5 15.078 13.3553 14.7227 13.066 14.434C12.7773 14.1447 12.422 14 12 14C11.578 14 11.2227 14.1447 10.934 14.434C10.6447 14.7227 10.5 15.078 10.5 15.5C10.5 15.922 10.6447 16.2773 10.934 16.566C11.2227 16.8553 11.578 17 12 17ZM9 9.5H15V7.5C15 6.66667 14.7083 5.95833 14.125 5.375C13.5417 4.79167 12.8333 4.5 12 4.5C11.1667 4.5 10.4583 4.79167 9.875 5.375C9.29167 5.95833 9 6.66667 9 7.5V9.5Z'
        fill='#214A4A'
      />
    </svg>
  );
};

export default LockIcon;
