import React from 'react';

const RightIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
    >
      <path
        d='M6.41383 11.936L3.32583 8.864C3.29383 8.82133 3.27783 8.776 3.27783 8.728C3.27783 8.68 3.29383 8.63467 3.32583 8.592L4.38183 7.552C4.41383 7.52 4.45383 7.504 4.50183 7.504C4.54983 7.504 4.59517 7.52 4.63783 7.552L6.36583 9.28C6.39783 9.312 6.43783 9.328 6.48583 9.328C6.53383 9.328 6.57917 9.312 6.62183 9.28C7.69917 8.128 9.31517 6.41067 11.4698 4.128C11.5018 4.08533 11.5445 4.064 11.5978 4.064C11.6512 4.064 11.6938 4.08 11.7258 4.112L12.8138 5.152C12.8565 5.184 12.8778 5.224 12.8778 5.272C12.8778 5.32 12.8618 5.36533 12.8298 5.408C11.4538 6.85867 9.4005 9.03467 6.66983 11.936C6.63783 11.9787 6.59517 12 6.54183 12C6.4885 12 6.44583 11.9787 6.41383 11.936Z'
        fill='#F2F2F2'
      />
    </svg>
  );
};

export default RightIcon;
