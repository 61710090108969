import React from 'react';

const TwitterIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
    >
      <g clipPath='url(#clip0_338_1648)'>
        <path
          d='M14.3358 3.76878C13.8345 3.99278 13.2798 4.14745 12.6718 4.23278C12.9705 4.06211 13.2292 3.83811 13.4478 3.56078C13.6665 3.28345 13.8292 2.97411 13.9358 2.63278C13.3918 2.97411 12.7838 3.20878 12.1118 3.33678C11.8558 3.04878 11.5438 2.82478 11.1758 2.66478C10.8078 2.50478 10.4212 2.42478 10.0158 2.42478C9.49316 2.42478 9.01316 2.55545 8.57582 2.81678C8.13849 3.07811 7.79182 3.42745 7.53582 3.86478C7.27982 4.30211 7.15182 4.78211 7.15182 5.30478C7.15182 5.56078 7.17316 5.77945 7.21582 5.96078C6.04249 5.89678 4.93849 5.59811 3.90382 5.06478C2.89049 4.54211 2.02116 3.83811 1.29582 2.95278C1.03982 3.41145 0.911824 3.88878 0.911824 4.38478C0.911824 4.88078 1.02649 5.33945 1.25582 5.76078C1.48516 6.18211 1.79716 6.52611 2.19182 6.79278C1.72249 6.78211 1.29049 6.65945 0.895824 6.42478V6.47278C0.895824 7.16611 1.11182 7.77411 1.54382 8.29678C1.97582 8.81945 2.52782 9.15011 3.19982 9.28878C2.96516 9.35278 2.70916 9.38478 2.43182 9.38478C2.23982 9.38478 2.06382 9.36878 1.90382 9.33678C2.08516 9.91278 2.42116 10.3848 2.91182 10.7528C3.40249 11.1208 3.95716 11.3101 4.57582 11.3208C4.08516 11.7154 3.53316 12.0194 2.91982 12.2328C2.30649 12.4461 1.66916 12.5528 1.00782 12.5528C0.869158 12.5528 0.655824 12.5421 0.367824 12.5208H0.319824C1.65316 13.3741 3.12516 13.8008 4.73582 13.8008C6.00516 13.8008 7.17316 13.5554 8.23982 13.0648C9.22116 12.6061 10.0745 11.9661 10.7998 11.1448C11.4718 10.3768 11.9945 9.50211 12.3678 8.52078C12.7198 7.57145 12.8958 6.61145 12.8958 5.64078V5.25678C13.4612 4.85145 13.9412 4.35545 14.3358 3.76878Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_338_1648'>
          <rect
            width='16.02'
            height='16'
            fill='white'
            transform='matrix(1 0 0 -1 0.310059 16.1206)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TwitterIcon;
