import React from 'react';
import classes from './Blogs.module.css';
import Blog from '../Blog/Blog';
const Blogs = () => {
  return (
    <>
      <div data-aos="zoom-in" className={classes.Blogs__content}>
        <h5>Latest News</h5>
        <h3>Our Latest Blog Post</h3>
        <p>
          Vestibulum lectus mauris ultrices eros in The course is a lot of fun.
          Adipiscing sad risus nec feugiat the aenean bcom here
        </p>
      </div>
      <div className={classes.Blogs}>
        <Blog />
        <Blog />
        <Blog />
      </div>
    </>
  );
};

export default Blogs;
