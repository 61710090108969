import React from "react";
import classes from "./SingleLink.module.css";
const SingleLink = () => {
    return (
        <div className={classes.foot__link}>
            <h2>About Us</h2>
            <div className="row">
                <div className="col-lg-6 col-sm-12">
                    <div className={classes.layout}>
                        <li>About Us</li>
                        <li>Company</li>
                        <li>Privacy Policy</li>
                        <li>Terms & conditions</li>
                        <li>Pricing</li>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <div className={classes.layout}>
                        <li>Delivery Policy</li>
                        <li>Returned Policy</li>
                        <li>FAQs</li>
                        <li>Contact Us</li>
                        <li>Methods of Payment</li>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleLink;
