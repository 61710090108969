import React from 'react';
import './Assessment.css';
import Img from '../../assests/assessment1.png';
import AsideSmart from '../../Components/AsideSmart/AsideSmart';
import SmartHeading from '../../Components/SmartHeading/SmartHeading';
import LeftOne from '../../assests/left-pic-1.png';
import LeftFour from '../../assests/left-pic-Four.png';
import LeftTwo from '../../assests/left-pic-two.jpg';
import LeftThree from '../../assests/left-pic-3.jpg';
import MiddleAssessment from '../../Components/MiddleAssessment/MiddleAssessment';
import SidebarLinks from '../../Components/SidebarLinks/SidebarLinks';
import { expertTeacherLinks } from '../../data';
function Assessment() {
  return (
      <>
          <SmartHeading
              Img={Img}
              heading="Teacher Evaluation andAssessment Tools"
              subheading="for Your Professional Growth"
          />
          <section className="ass4 p-3 p-md-5">
              <div className="row gy-4 main-row">
                  <div className="col-xl-3 px-3">
                      <SidebarLinks links={expertTeacherLinks} />
                  </div>
                  <div className="col-xl-6 px-3">
                      <MiddleAssessment />
                  </div>
                  <div className="col-xl-3 px-3">
                      <AsideSmart width="100%">
                  <div className="assessment__left">
                      <h3>Teacher Evaluation and Assessment Tools</h3>
                      <h5>Teacher Competency Assessment (TCA)</h5>
                      <p>
                          The full version (112 items, 19 Indicators and 8
                          Domains) is only available for certified Expert
                          Teachers. This tool may be used by Self, Principal,
                          Peers, Supervisors and Experts
                      </p>
                      <img src={LeftOne} alt="left-img" />
                      <h5>Simplified Teacher Competency Assessment</h5>
                      <p>
                          The Simplified TCA assessment tool is made up of 19
                          items (Indicators) and 8 Domains.
                      </p>
                      <img src={LeftThree} alt="left-three" />
                      <h5>Classroom Observation</h5>
                      <p>
                          Classroom Observation may be used by Principal, Peers,
                          Supervisors and Experts.
                      </p>
                      <img src={LeftTwo} alt="left-two" />
                      <h5>Student Perception Survey</h5>
                      <p>
                          Students-Rating their Teacher. This tool can be used
                          by Students
                      </p>
                      <img src={LeftFour} alt="last" />
                  </div>
              </AsideSmart>
                  </div>
              </div>
          </section>

      </>
  );
}

export default Assessment;
