import React from 'react';
import classes from './MiddleSmartTeaching.module.css';
import SmartText from '../SmartText/SmartText';
import Teacher from '../../assests/teacher.png';
import promImg from '../../assests/prof.png';
import StudentImg from '../../assests/boy.png';
import AnaPic from '../../assests/analyes.png';
const MiddleSmartTeaching = () => {
  return (
    <section className={classes.middleSmart}>
      <h3>SMART TEACHING SYSTEM®</h3>
      <div className={classes.middleSmart__top}>
        <SmartText>
          Smart Teaching System® is a learning platform that combines the
          benefits of powerful models, strategies and tools to help educators
          and students to reach their full potential. It is a systematic
          approach to building teaching effectiveness and efficiency.{' '}
        </SmartText>
        <SmartText>
          Smart Teaching System® is a result of four year research in education
          systems throughout the world. The main finding is that mainstream
          methods of teaching and assessment fails to produce the students
          needed to be competitive.{' '}
        </SmartText>
        <SmartText>
          Smart Teaching is a strategy for performance excellence. We believe
          that the best teachers are those who show you where to look, but don’t
          tell you what to see. And that a teacher is a compass that activates
          the magnets of curiosity, knowledge and wisdom.
        </SmartText>
      </div>
      <h3>Becoming a Smart School</h3>
      <div className={classes.middleSmart__z}>
        <img
          src={promImg}
          alt='teacher'
        />
        <div className={classes.z__content}>
          <h4>Smart Teaching Helps Teachers Develop Greater Effectiveness</h4>
          <SmartText>
            Research found that Effective teachers are the most important factor
            contributing to student achievement. The system is designed to help
            teachers develop greater effectiveness.
          </SmartText>
          <SmartText>
            Discover the Teaching Framework that improves teaching effectiveness
            faster than any traditional method, and raises student achievement
            gains to an unprecedented levels…
          </SmartText>
          <SmartText>
            Discover how students learn at Twice the Rate they do in the
            classrooms of average teachers – they learn in six months what
            students taught by the average teachers take a year to learn. And in
            the classrooms of the least effective teachers, the same learning
            will take Two years.
          </SmartText>
        </div>
      </div>
      <div className={classes.middleSmart__z}>
        <div className={`${classes.z__content} ${classes.blockA}`}>
          <h4>Smart Teaching Helps Teachers Develop Greater Effectiveness</h4>
          <SmartText>
            What teaching strategies works best? The best teaching strategies
            are those based on research. These research-based, also known as
            evidence-based, strategies are supported by significant and reliable
            evidence derived from experiments and linked to successful student
            outcomes.
          </SmartText>
          <SmartText>
            However, many teachers and educators are aware of evidence-based
            teaching strategies but many don’t know how to used them in an
            efficient way. For students’ results to reflect these high
            effect-sizes strategies, teachers need to develop the skills of
            their use.
          </SmartText>
        </div>
        <img
          src={Teacher}
          alt='teacher'
          id='blockB'
        />
      </div>
      <div className={classes.middleSmart__z}>
        <img
          src={StudentImg}
          alt='teacher'
          id='blockC'
        />
        <div
          className={classes.z__content}
          id='blockD'
        >
          <h4>Smart Teaching Focuses on Student Learning</h4>
          <SmartText>
            Smart Teaching focuses on student learning and the teacher
            behaviours and classroom processes that promote better student
            outcomes. Teacher effectiveness is defined as a teacher’s ability to
            produce higher than expected gains in students’ standardized test
            scores.
          </SmartText>
          <SmartText>
            The quality of teaching and learning provision is by far the most
            salient influences on students’ learning – regardless of their
            gender or backgrounds. It is the most important school-related
            factor influencing student achievement. Smart Teaching System
            provides teachers the right tools and resources that enable them to
            create great learning results.
          </SmartText>
        </div>
      </div>
      <div className={classes.middleSmart__z}>
        <div className={`${classes.z__content} ${classes.blockZ}`}>
          <h4>Smart Teaching Helps Teachers Develop Greater Effectiveness</h4>
          <SmartText>
            What teaching strategies works best? The best teaching strategies
            are those based on research. These research-based, also known as
            evidence-based, strategies are supported by significant and reliable
            evidence derived from experiments and linked to successful student
            outcomes.
          </SmartText>
          <SmartText>
            However, many teachers and educators are aware of evidence-based
            teaching strategies but many don’t know how to used them in an
            efficient way. For students’ results to reflect these high
            effect-sizes strategies, teachers need to develop the skills of
            their use.
          </SmartText>
        </div>
        <img
          src={AnaPic}
          alt='teacher'
        />
      </div>
    </section>
  );
};

export default MiddleSmartTeaching;
