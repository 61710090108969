import React from 'react';
import classes from './Courses.module.css';
import Course from '../Course/Course';
function Courses() {
    return (
        <>
            <div data-aos="zoom-in" className={classes.coures_content}>
                <h5 className="text-center">Educational Paths</h5>
                <h3 className="text-center">
                    Perfect online learning paths for your career
                </h3>
                <ul>
          <li className={classes.active}>All</li>
          <li>Education and development</li>
          <li>Education and development</li>
          <li>Educational system design</li>
        </ul>
        <ul>
          <li>Teacher evaluation</li>
          <li>The three-level HOST model</li>
        </ul>
      </div>

            <div className="row p-5">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <Course />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <Course />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <Course />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <Course />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <Course />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <Course />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <Course />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <Course />
                </div>
            </div>
        </>
    );
}

export default Courses;
