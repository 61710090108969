import React from 'react';

const VIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
    >
      <path
        d='M0.00976562 5.33654L0.569766 6.07254L0.889766 5.84854C1.1351 5.68854 1.35377 5.58187 1.54577 5.52854C1.8231 5.45387 2.02043 5.48054 2.13777 5.60854C2.2231 5.71521 2.38843 6.1312 2.63377 6.85654C2.77243 7.29387 3.01243 8.06187 3.35377 9.16054C3.6631 10.1739 3.8871 10.8885 4.02577 11.3045C4.24977 12.0085 4.4151 12.4672 4.52177 12.6805C4.68177 13.0219 4.90043 13.3312 5.17777 13.6085C5.50843 13.9179 5.8551 14.1099 6.21777 14.1845C6.64443 14.2805 7.06043 14.1952 7.46577 13.9285C7.8711 13.6619 8.35643 13.2832 8.92177 12.7925C9.62577 12.1632 10.2818 11.4912 10.8898 10.7765C11.6471 9.88054 12.2764 8.98454 12.7778 8.08854C13.3644 7.0112 13.7484 5.96054 13.9298 4.93654C14.0791 4.10454 13.9938 3.43254 13.6738 2.92054C13.3964 2.46187 12.9644 2.17387 12.3778 2.05654C11.8231 1.94987 11.2284 2.01387 10.5938 2.24854C9.9591 2.4832 9.41243 2.85654 8.95377 3.36854C8.45243 3.92321 8.1431 4.56321 8.02577 5.28854C8.40977 5.05387 8.75643 4.94721 9.06577 4.96854C9.36443 4.98987 9.59377 5.10721 9.75377 5.32054C9.91377 5.53387 9.9911 5.80854 9.98577 6.14454C9.98043 6.48054 9.87643 6.83521 9.67377 7.20854C9.25777 7.98721 8.87643 8.6032 8.52977 9.05654C8.1831 9.50987 7.91643 9.73654 7.72977 9.73654C7.5431 9.73654 7.35377 9.49121 7.16177 9.00054C7.0231 8.6272 6.82577 7.93921 6.56977 6.93654C6.50577 6.66987 6.42043 6.22187 6.31377 5.59254C6.18577 4.79254 6.07377 4.21121 5.97777 3.84854C5.81777 3.22987 5.60977 2.78187 5.35377 2.50454C5.0551 2.16321 4.6711 2.04054 4.20177 2.13654C3.78577 2.22187 3.3111 2.44054 2.77777 2.79254C2.3511 3.06987 1.89777 3.42721 1.41777 3.86454C1.07643 4.18454 0.740432 4.52587 0.409766 4.88854L0.00976562 5.33654Z'
        fill='white'
      />
    </svg>
  );
};

export default VIcon;
