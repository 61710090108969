import React from 'react';

const EyesIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill='none'
    >
      <g clipPath='url(#clip0_109_4037)'>
        <path
          d='M6.71467 4.64612C8.69334 4.67412 10.3453 5.27145 11.6707 6.43812C12.1 6.80212 12.5013 7.28278 12.8747 7.88012C12.8933 7.94545 12.8887 7.99212 12.8607 8.02012C11.7687 9.78412 10.126 10.8294 7.93267 11.1561C5.76734 11.4828 3.84001 11.0021 2.15067 9.71412C1.56267 9.28478 1.06334 8.72012 0.652672 8.02012C0.643339 8.00145 0.638672 7.97578 0.638672 7.94312C0.638672 7.91045 0.643339 7.88945 0.652672 7.88012C1.70734 6.15345 3.34534 5.10345 5.56667 4.73012L5.70667 4.71612C5.82801 4.69745 5.92134 4.68812 5.98667 4.68812C6.09867 4.67878 6.26201 4.66945 6.47667 4.66012L6.71467 4.64612ZM7.94667 9.82612C9.28134 9.57412 10.35 8.97678 11.1527 8.03412C11.1807 7.99678 11.1923 7.96645 11.1877 7.94312C11.183 7.91978 11.1667 7.88945 11.1387 7.85212C10.6533 7.27345 10.042 6.82078 9.30467 6.49412C8.89401 6.30745 8.42734 6.16278 7.90467 6.06012C8.72601 6.53612 9.13434 7.16612 9.12967 7.95012C9.12501 8.73412 8.73067 9.35945 7.94667 9.82612ZM5.56667 9.82612C4.78267 9.35945 4.38601 8.73412 4.37667 7.95012C4.37667 7.17545 4.76867 6.54545 5.55267 6.06012C5.17934 6.15345 4.90401 6.22812 4.72667 6.28412C3.76534 6.61078 2.98134 7.13345 2.37467 7.85212C2.31867 7.92678 2.31634 7.98978 2.36767 8.04112C2.41901 8.09245 2.48667 8.16478 2.57067 8.25812L2.71067 8.41212C3.47601 9.14012 4.42801 9.61145 5.56667 9.82612ZM5.93067 7.61412C6.08934 7.60478 6.22234 7.55112 6.32967 7.45312C6.43701 7.35512 6.49067 7.23612 6.49067 7.09612C6.49067 6.95612 6.43467 6.83478 6.32267 6.73212C6.21067 6.62945 6.07534 6.57812 5.91667 6.57812C5.75801 6.57812 5.62267 6.62945 5.51067 6.73212C5.39867 6.83478 5.34501 6.95845 5.34967 7.10312C5.35434 7.24778 5.41267 7.36912 5.52467 7.46712C5.63667 7.56512 5.77201 7.61412 5.93067 7.61412Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_109_4037'>
          <rect
            width='14'
            height='14'
            fill='white'
            transform='matrix(1 0 0 -1 0.638672 14.9501)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EyesIcon;
