import React from 'react';

const ReviewIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
    >
      <g clipPath='url(#clip0_109_3985)'>
        <path
          d='M14.0165 7.46606C13.8459 7.6474 13.5792 7.9194 13.2165 8.28206L13.1685 8.33006C12.2192 9.25806 11.5045 9.9514 11.0245 10.4101C10.8752 10.5594 10.7632 10.6767 10.6885 10.7621C10.6459 10.8047 10.6245 10.8687 10.6245 10.9541L11.4405 15.6901V15.7221C11.4299 15.7327 11.3765 15.7114 11.2805 15.6581C11.0885 15.5621 10.8112 15.4181 10.4485 15.2261L7.13655 13.4821C7.06188 13.4394 6.98188 13.4447 6.89655 13.4981L5.26455 14.3621C4.15521 14.9487 3.31788 15.3861 2.75255 15.6741C2.73121 15.6954 2.70988 15.7061 2.68855 15.7061C2.63521 15.7274 2.61921 15.6847 2.64055 15.5781L2.81655 14.4741C3.09388 12.8954 3.29655 11.7114 3.42455 10.9221C3.43521 10.9007 3.43255 10.8714 3.41655 10.8341C3.40055 10.7967 3.38188 10.7674 3.36055 10.7461L2.81655 10.2181C2.10188 9.52473 1.56855 8.99673 1.21655 8.63406C0.88588 8.31406 0.512547 7.9194 0.0965469 7.45006C0.0645469 7.41806 0.0538802 7.38873 0.0645469 7.36206C0.0752135 7.3354 0.10188 7.31673 0.144547 7.30606L3.98455 6.74606C4.28321 6.69273 4.50721 6.66073 4.65655 6.65006C4.72055 6.6394 4.76855 6.6234 4.80055 6.60206C4.83255 6.58073 4.86455 6.53806 4.89655 6.47406C5.79255 4.6394 6.46988 3.2634 6.92855 2.34606L6.97655 2.26606C6.98721 2.24473 7.00321 2.2314 7.02455 2.22606C7.04588 2.22073 7.06455 2.22873 7.08055 2.25006C7.09655 2.2714 7.10455 2.29273 7.10455 2.31406L9.16855 6.49006C9.21121 6.58606 9.28588 6.6394 9.39255 6.65006L13.9685 7.32206C14.0112 7.32206 14.0379 7.3354 14.0485 7.36206C14.0592 7.38873 14.0485 7.4234 14.0165 7.46606Z'
          fill='black'
        />
      </g>
      <defs>
        <clipPath id='clip0_109_3985'>
          <rect
            width='16.02'
            height='16'
            fill='white'
            transform='matrix(1 0 0 -1 0.0507812 16.9701)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReviewIcon;
