import React from "react";
import "./Navbar.css";
import Logo from "../../assests/Logo.png";
import { Link, NavLink } from "react-router-dom";
import MainBtn from "../MainBtn/MainBtn";
import { FaAngleDown } from "react-icons/fa";
const Navbar = () => {

    const close = () =>{
    document.querySelector(".navbar-collapse").classList.remove("show");
    }
    return (
        <>
            <nav className="active navbar navbar-expand-lg py-lg-0 py-3">
                <div className="container-fluid">
                    <Link className="navbar-brand d-lg-none" href="/">
                        <img
                            src={Logo}
                            alt="logo"
                            className="logo"
                            width={133}
                            height={81}
                        />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        aria-controls="navbarSupportedContent"
                        data-bs-target="#navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div
                        className="collapse navbar-collapse links align-items-center py-2"
                        id="navbarSupportedContent"
                    >
                        <Link
                            className="navbar-brand d-none d-lg-block"
                            href="/"
                        >
                            <img
                                src={Logo}
                                alt="logo"
                                className="logo"
                                width={133}
                                height={81}
                            />
                        </Link>
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0 p-0">
                            <li>
                                <NavLink onClick={close}
                                    to="/"
                                    
                                    className={({ isActive }) =>
                                        isActive
                                            ? "active nav-item"
                                            : "nav-item"
                                    }
                                    end
                                >
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <Link
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    id="navbarDropdown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Smart Teaching System
                                </Link>
                                <ul
                                    className="dropdown-menu p-0"
                                    aria-labelledby="navbarDropdown"
                                >
                                    <li className="m-0">
                                        <NavLink onClick={close}
                                            to="/smart-teaching"
                                            className={({ isActive }) =>
                                                isActive
                                                    ? "active dropdown-item"
                                                    : "dropdown-item"
                                            }
                                            end
                                        >
                                            Smart Teaching System
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink onClick={close}
                                            to="/expert-teacher3"
                                            className={({ isActive }) =>
                                                isActive
                                                    ? "active dropdown-item"
                                                    : "dropdown-item"
                                            }
                                            end
                                        >
                                            Factors Affecting Student
                                            Achievement
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <Link
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    id="navbarDropdown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Expert Teacher
                                </Link>
                                <ul
                                    className="dropdown-menu p-0"
                                    aria-labelledby="navbarDropdown"
                                >
                                    <li className="m-0">
                                        <NavLink onClick={close}
                                            to="/expertteacher"
                                            className={({ isActive }) =>
                                                isActive
                                                    ? "active dropdown-item"
                                                    : "dropdown-item"
                                            }
                                            end
                                        >
                                            Expert Teacher
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink onClick={close}
                                            to="/expertteachingacademy"
                                            className={({ isActive }) =>
                                                isActive
                                                    ? "active dropdown-item"
                                                    : "dropdown-item"
                                            }
                                            end
                                        >
                                            Expert Teacher Academy
                                        </NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink onClick={close}
                                            to="/expert-teacher3"
                                            className={({ isActive }) =>
                                                isActive
                                                    ? "active dropdown-item"
                                                    : "dropdown-item"
                                            }
                                            end
                                        >
                                            Individual Professional Development
                                            Plan
                                        </NavLink>
                                    </li> */}
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <Link
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    id="navbarDropdown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Assessments
                                </Link>
                                <ul
                                    className="dropdown-menu p-0"
                                    aria-labelledby="navbarDropdown"
                                >
                                    <li className="m-0">
                                        <NavLink onClick={close}
                                            to="/assessment"
                                            className={({ isActive }) =>
                                                isActive
                                                    ? "active dropdown-item"
                                                    : "dropdown-item"
                                            }
                                            end
                                        >
                                            Assessments
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink onClick={close}
                                            to="/ClassroomObservationInstruments"
                                            className={({ isActive }) =>
                                                isActive
                                                    ? "active dropdown-item"
                                                    : "dropdown-item"
                                            }
                                            end
                                        >
                                            Classroom Observation Instruments
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink onClick={close}
                                            to="/StudentsRatingtheirTeachers"
                                            className={({ isActive }) =>
                                                isActive
                                                    ? "active dropdown-item"
                                                    : "dropdown-item"
                                            }
                                            end
                                        >
                                            Students Rating their Teachers
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink onClick={close}
                                            to="/SimplifiedTeachersCompetencyAssessment"
                                            className={({ isActive }) =>
                                                isActive
                                                    ? "active dropdown-item"
                                                    : "dropdown-item"
                                            }
                                            end
                                        >
                                            Simplified Teachers Competency
                                            Assessment
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink onClick={close}
                                            to="/TeacherCompetencyAssessment"
                                            className={({ isActive }) =>
                                                isActive
                                                    ? "active dropdown-item"
                                                    : "dropdown-item"
                                            }
                                            end
                                        >
                                            Teacher Competency Assessment
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <Link
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    id="navbarDropdown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Resources
                                </Link>
                                <ul
                                    className="dropdown-menu p-0"
                                    aria-labelledby="navbarDropdown"
                                >
                                    <li className="m-0">
                                        <NavLink onClick={close}
                                            to="/blogs"
                                            className={({ isActive }) =>
                                                isActive
                                                    ? "active dropdown-item"
                                                    : "dropdown-item"
                                            }
                                            end
                                        >
                                            Blogs
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink onClick={close}
                                            to="/courses"
                                            className={({ isActive }) =>
                                                isActive
                                                    ? "active dropdown-item"
                                                    : "dropdown-item"
                                            }
                                            end
                                        >
                                            General Course
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink onClick={close}
                                            to="/books"
                                            className={({ isActive }) =>
                                                isActive
                                                    ? "active dropdown-item"
                                                    : "dropdown-item"
                                            }
                                            end
                                        >
                                            E-Books
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink onClick={close}
                                            to="/resources"
                                            className={({ isActive }) =>
                                                isActive
                                                    ? "active dropdown-item"
                                                    : "dropdown-item"
                                            }
                                            end
                                        >
                                            Resources
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <NavLink onClick={close}
                                    to="/membership"
                                    className={({ isActive }) =>
                                        isActive
                                            ? "active nav-item"
                                            : "nav-item"
                                    }
                                    end
                                >
                                    membership
                                </NavLink>
                            </li>
                        </ul>

                        <div className="auth ">
                            <Link to="/login">
                                <MainBtn shadow>Login</MainBtn>
                            </Link>
                            <span className="p-2">
                                Ar <FaAngleDown />
                            </span>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
