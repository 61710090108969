import React from 'react';

function Love() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
    >
      <g clipPath='url(#clip0_379_6737)'>
        <path
          d='M8.90018 15.3439C8.83618 15.2586 8.75084 15.1359 8.64418 14.9759C8.49484 14.7732 8.38284 14.6239 8.30818 14.5279C8.03084 14.1866 7.70018 13.8239 7.31618 13.4399C7.08151 13.2159 6.71884 12.8852 6.22818 12.4479L5.39618 11.6959C4.50018 10.8852 3.83351 10.2666 3.39618 9.83989C2.39351 8.83722 1.70284 7.78922 1.32418 6.69589C0.945509 5.60256 0.878843 4.58655 1.12418 3.64789C1.32684 2.85855 1.73751 2.18389 2.35618 1.62389C2.97484 1.06389 3.68951 0.725222 4.50018 0.607888C5.42818 0.469221 6.29218 0.610555 7.09218 1.03189C7.89218 1.45322 8.50018 2.08522 8.91618 2.92789C8.91618 2.94922 8.92418 2.97322 8.94018 2.99989C8.95618 3.02656 8.98018 3.03455 9.01218 3.02389L9.02818 2.97589C9.66818 1.68522 10.6655 0.906554 12.0202 0.639888C13.3108 0.373221 14.4682 0.666554 15.4922 1.51989C16.6015 2.47989 17.0922 3.71189 16.9642 5.21589C16.8468 6.48522 16.3295 7.70656 15.4122 8.87989C15.0495 9.33856 14.6175 9.81322 14.1162 10.3039C13.7855 10.6239 13.3162 11.0559 12.7082 11.5999C11.5562 12.6452 10.7028 13.4399 10.1482 13.9839C9.73218 14.3679 9.36951 14.8372 9.06018 15.3919L9.02818 15.4399C9.01751 15.4506 8.99884 15.4479 8.97218 15.4319C8.94551 15.4159 8.92151 15.3866 8.90018 15.3439Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_379_6737'>
          <rect
            width='16.02'
            height='16'
            fill='white'
            transform='matrix(1 0 0 -1 0.97998 16)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Love;
