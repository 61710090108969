import React from 'react';
import classes from './MiddleAssessment.module.css';
import TextAssessment from '../TextAssessment/TextAssessment';
import SmartText from '../SmartText/SmartText';
import MiddlePic from '../../assests/middle_ass.png';
import AssessmentCard from '../AssessmentCard/AssessmentCard';
import Logoone from '../../assests/cardlogo.png';
import LogoTwo from '../../assests/cardlogo2.png';
import LogoThree from '../../assests/cardlogo3.png';
const MiddleAssessment = () => {
  const items = ['Self', 'Principal', 'Peers', 'Supervisors'];
  const Sitems = ['Students'];
  return (
      <section className={classes.middle_assessment}>
          <h3 className={classes.h3}>
              Teacher Evaluation and Assessment Tools
          </h3>
          <TextAssessment>
              Teacher evaluation has typically two major purposes. First, it
              seeks to improve the teacher own practice by identifying strengths
              and weaknesses for further professional development – the
              improvement function. Second, it is aimed at ensuring that
              teachers perform at their best to enhance student learning – the
              accountability function.{" "}
          </TextAssessment>
          <TextAssessment>
              Evaluation and assessment tools we offer focus on the provision of
              feedback useful for the improvement of teaching practices, through
              professional development. This is to help teachers learn about,
              reflect on, and improve their practice.
          </TextAssessment>
          <TextAssessment>
              Our evaluation and assessment tools do not entail
              performance-based career advancement and/or salaries, bonus, pay,
              or the possibility of sanctions for underperformance. Therefore,
              teacher evaluation for improvement purposes is likely to benefit
              from conditions such as:
          </TextAssessment>
          <div className={classes.middle_assessment__layout}>
              <SmartText>A non-threatening evaluation</SmartText>
              <SmartText>Providing and receiving feedback</SmartText>
              <SmartText>
                  Opportunities to enhance competencies and improve practice
              </SmartText>
          </div>
          <TextAssessment>
              Three key measures should be used collectively for teacher
              evaluation:
          </TextAssessment>
          <div className={classes.middle_assessment__layout}>
              <SmartText>
                  Student Achievement Gains (standardised test scores)
              </SmartText>

              <SmartText>Classroom Observation Instruments</SmartText>
              <SmartText>Student Perception Surveys</SmartText>
          </div>
          <TextAssessment>
              Evaluation and assessment tools we offer focus on the provision of
              feedback useful for the improvement of teaching practices, through
              professional development. This is to help teachers learn about,
              reflect on, and improve their practice.
          </TextAssessment>
          <img src={MiddlePic} alt="middle_assment" />
          <TextAssessment>
              Research found that these formulas can provide an accurate measure
              of a teacher’s performance without putting too much weight on any
              one measure.
          </TextAssessment>
          <TextAssessment>
              High quality student achievement measures have been shown to be
              objective and more accurate indicators of teacher performance than
              many traditional measures and are strongly correlated to other
              signs of teaching quality.
          </TextAssessment>
          <h3 className={classes.h3}>Student Learning Objectives (SLO)</h3>
          <TextAssessment>
              Student Learning Objectives (SLO) is a process to document a
              measure of educator effectiveness based on student achievement of
              content standards.
          </TextAssessment>
          <h3 className={classes.h3}>
              Smart Teacher Evaluation and Assessment Tools
          </h3>
          <TextAssessment>
              Research shows that observations are most effective when conducted
              multiple times per year. Other research found that observations
              are more accurate when they are conducted by more than one person.
          </TextAssessment>
          <div className="row justify-content-center g-5 mx-3">
              <div className="col-xxl-4 col-lg-6 col-sm-12 ">
                  <AssessmentCard
                      items={items}
                      logo={Logoone}
                      title="Teaching Skills"
                  />
              </div>
              <div className="col-xxl-4 col-lg-6 col-sm-12 ">
                  <AssessmentCard
                      items={items}
                      logo={LogoTwo}
                      title="Classroom Observation"
                  />
              </div>
              <div className="col-xxl-4 col-lg-6 col-sm-12 ">
                  <AssessmentCard
                      items={Sitems}
                      logo={LogoThree}
                      title="Students-Rating their teacher"
                  />
              </div>
          </div>
      </section>
  );
};

export default MiddleAssessment;
