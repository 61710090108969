import React from 'react';

const PdfIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
    >
      <path
        d='M5.60078 13.3542H4.80078V11.7542H5.60078C5.81295 11.7542 6.01644 11.8384 6.16647 11.9885C6.3165 12.1385 6.40078 12.342 6.40078 12.5542C6.40078 12.7663 6.3165 12.9698 6.16647 13.1198C6.01644 13.2699 5.81295 13.3542 5.60078 13.3542ZM11.2008 16.5542V11.7542H12.0008C12.213 11.7542 12.4164 11.8384 12.5665 11.9885C12.7165 12.1385 12.8008 12.342 12.8008 12.5542V15.7542C12.8008 15.9663 12.7165 16.1698 12.5665 16.3198C12.4164 16.4699 12.213 16.5542 12.0008 16.5542H11.2008Z'
        fill='#214A4A'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M4 5C4 4.46957 4.19451 3.96086 4.54073 3.58579C4.88695 3.21071 5.35652 3 5.84615 3H15.9471L20 7.39067V21C20 21.5304 19.8055 22.0391 19.4593 22.4142C19.1131 22.7893 18.6435 23 18.1538 23H5.84615C5.35652 23 4.88695 22.7893 4.54073 22.4142C4.19451 22.0391 4 21.5304 4 21V5ZM7.07692 11H5.23077V17.6667H6.46154V15H7.07692C7.56655 15 8.03613 14.7893 8.38235 14.4142C8.72857 14.0391 8.92308 13.5304 8.92308 13C8.92308 12.4696 8.72857 11.9609 8.38235 11.5858C8.03613 11.2107 7.56655 11 7.07692 11ZM12 11H10.1538V17.6667H12C12.4896 17.6667 12.9592 17.456 13.3054 17.0809C13.6516 16.7058 13.8462 16.1971 13.8462 15.6667V13C13.8462 12.4696 13.6516 11.9609 13.3054 11.5858C12.9592 11.2107 12.4896 11 12 11ZM15.0769 17.6667V11H18.7692V12.3333H16.3077V13.6667H17.5385V15H16.3077V17.6667H15.0769Z'
        fill='#214A4A'
      />
    </svg>
  );
};

export default PdfIcon;
