import React from 'react';

const FaceBookIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
    >
      <path
        d='M11.976 5.59478H9.76798V4.13878C9.76798 3.89345 9.83731 3.71211 9.97598 3.59478C10.0826 3.50945 10.216 3.46678 10.376 3.46678H11.944V1.06678L9.78398 1.05078C9.04798 1.05078 8.43998 1.22145 7.95998 1.56278C7.56531 1.84011 7.27198 2.22411 7.07998 2.71478C6.93065 3.10945 6.85598 3.53611 6.85598 3.99478V5.59478H5.47998V8.05878H6.85598V15.0508H9.76798V8.05878H11.72L11.976 5.59478Z'
        fill='white'
      />
    </svg>
  );
};

export default FaceBookIcon;
