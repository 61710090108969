import React from 'react';

const LinkedIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
    >
      <g clipPath='url(#clip0_338_1654)'>
        <path
          d='M13.5855 1.11242H1.72945C1.44145 1.11242 1.19612 1.21108 0.993453 1.40842C0.790786 1.60575 0.689453 1.84308 0.689453 2.12042V14.1204C0.689453 14.3978 0.790786 14.6351 0.993453 14.8324C1.19612 15.0298 1.44145 15.1284 1.72945 15.1284H13.5855C13.8628 15.1284 14.1028 15.0298 14.3055 14.8324C14.5081 14.6351 14.6095 14.3978 14.6095 14.1204V2.12042C14.6095 1.84308 14.5081 1.60575 14.3055 1.40842C14.1028 1.21108 13.8628 1.11242 13.5855 1.11242ZM4.91345 12.8404H2.81745V6.52042H4.91345V12.8404ZM3.85745 5.65642H3.84145C3.50012 5.65642 3.22279 5.55242 3.00945 5.34442C2.79612 5.13642 2.68945 4.87508 2.68945 4.56042C2.68945 4.24575 2.79879 3.98442 3.01745 3.77642C3.23612 3.56842 3.52145 3.46442 3.87345 3.46442C4.22545 3.46442 4.50812 3.56842 4.72145 3.77642C4.93479 3.98442 5.04145 4.24575 5.04145 4.56042C5.04145 4.87508 4.93479 5.13642 4.72145 5.34442C4.50812 5.55242 4.22012 5.65642 3.85745 5.65642ZM12.4815 12.8404H10.3855V9.44842C10.3855 9.00042 10.3055 8.65908 10.1455 8.42442C9.96412 8.15775 9.69212 8.02442 9.32945 8.02442C9.06279 8.02442 8.82812 8.10442 8.62545 8.26442C8.46545 8.40308 8.33745 8.57908 8.24145 8.79242C8.19879 8.92042 8.17745 9.09108 8.17745 9.30442V12.8404H6.08145V9.89642C6.09212 7.89108 6.09212 6.76575 6.08145 6.52042H8.17745V7.41642C8.61479 6.72308 9.24412 6.37642 10.0655 6.37642C10.7908 6.37642 11.3668 6.60575 11.7935 7.06442C12.2521 7.56575 12.4815 8.28042 12.4815 9.20842V12.8404Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_338_1654'>
          <rect
            width='16.02'
            height='16'
            fill='white'
            transform='matrix(1 0 0 -1 0.679688 16.1206)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkedIcon;
